@tailwind base;
@tailwind components;
@tailwind utilities;

.hidden-scroll {
  -ms-overflow-style: none !important;
  scrollbar-width: 1px;
  margin-bottom: 5px;
}
.hidden-scroll::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}
.hidden-scroll::-webkit-scrollbar-track {
  background: rgba(22, 163, 74, 0.2);
  border-radius: 15px;
}
.hidden-scroll::-webkit-scrollbar-thumb {
  background: rgb(27, 54, 37);
}

.custom-checkbox {
  position: relative;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}
.custom-checkbox:checked {
  background-color: #003A7B;
  border-color: #003A7B;
}
.custom-checkbox:checked::before {
  content: "\2713"; /* Checkmark Unicode character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
}

.spinner {
  position: relative;
  width: 22.4px;
  height: 22.4px;
}

.spinner::before,
.spinner::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  animation: spinner-b4c8mmmd 0.5s backwards,
    spinner-49opz7md 1.25s 0.5s infinite ease;
  border: 5.6px solid #474bff;
  border-radius: 50%;
  box-shadow: 0 -33.6px 0 -5.6px #474bff;
  position: absolute;
}

.spinner::after {
  animation-delay: 0s, 1.25s;
}

@keyframes spinner-b4c8mmmd {
  from {
    box-shadow: 0 0 0 -5.6px #474bff;
  }
}

@keyframes spinner-49opz7md {
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 320px) {
  .custom-checkbox {
    width: 17px;
    height: 17px;
  }
}
@media (min-width: 450px) {
  .custom-checkbox {
    width: 15px;
    height: 15px;
  }
}

.avatar {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
  display: inline-block;
  position: relative;
}
.avatar-sm {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg,
.avatar-md {
  line-height: 2.5rem;
}
.avatar-lg {
  height: 3rem;
  width: 3rem;
}
.avatar-rounded {
  border-radius: 0.375rem;
}
.avatar-square {
  border-radius: 0;
}
.avatar-circle {
  border-radius: 9999px;
}
.avatar-img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.avatar-string {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  transform-origin: center;
}
.avatar-inner-sm {
  height: 2rem;
}
.avatar-inner-md {
  height: 2.5rem;
}
.avatar-inner-lg {
  height: 3rem;
}
.avatar-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.avatar-icon-sm {
  font-size: 1.125rem;
}
.avatar-icon-md,
.avatar-icon-sm {
  line-height: 1.75rem;
}
.avatar-icon-md {
  font-size: 1.25rem;
}
.avatar-icon-lg {
  font-size: 1.5rem;
  line-height: 2rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group-chained > .avatar,
.avatar-group-chained > .tooltip-wrapper {
  margin-left: -0.5rem;
}
[dir="rtl"] .avatar-group-chained > .avatar,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper {
  margin-right: -0.5rem;
}
.avatar-group-chained > .avatar:first-child,
.avatar-group-chained > .tooltip-wrapper:first-child {
  margin-left: 0;
}
[dir="rtl"] .avatar-group-chained > .avatar:first-child,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper:first-child {
  margin-right: 0;
}

.button.radius-round {
  border-radius: 0.375rem;
}
.button.radius-circle {
  border-radius: 9999px;
}
.button.radius-none {
  border-radius: 0;
}

.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.05;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.375rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  min-width: 160px;
  padding: 1rem;
  position: absolute;
  top:3rem;
  z-index: 30;
}
.dropdown-menu:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.dark .dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dropdown-menu.top-start {
  bottom: 100%;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.top-start {
  right: 0;
}
.dropdown-menu.top-center {
  left: 50%;
}
.dropdown-menu.top-center,
.dropdown-menu.top-end {
  bottom: 100%;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.top-end {
  left: 0;
}
.dropdown-menu.bottom-start {
  top: 100%;
  transform-origin: top;
}
[dir="ltr"] .dropdown-menu.bottom-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.bottom-start {
  right: 0;
}
.dropdown-menu.bottom-center {
  left: 50%;
}
.dropdown-menu.bottom-center,
.dropdown-menu.bottom-end {
  top: 100%;
  transform-origin: top;
}
[dir="ltr"] .dropdown-menu.bottom-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.bottom-end {
  left: 0;
}
.dropdown-menu.middle-start-top {
  left: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-menu.middle-start-bottom {
  bottom: 0;
  left: 100%;
  transform-origin: bottom;
}
.dropdown-menu.middle-end-top {
  right: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-menu.middle-end-bottom {
  bottom: 0;
  right: 100%;
  transform-origin: bottom;
}
.dropdown-toggle-default {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem;
}
.dropdown-toggle-default.dropdown-toggle-disabled {
  opacity: 0.6;
}
.dropdown-submenu {
  left: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-submenu-item {
  justify-content: space-between;
}
.dropdown-toggle-disabled {
  cursor: not-allowed;
}

.input {
  @apply border
  text-black	
  border-[#003A7B]
  dark:border-[#003A7B] 
  dark:bg-transparent 
  rounded-md 
  w-full 
  py-2 px-3 
  focus:outline-none 
  focus:ring-1 
  focus-within:ring-1 
  appearance-none 
  transition 
  duration-150 
  ease-in-out;
  &::placeholder {
    @apply text-gray-400;
  }

  &.input-invalid {
    @apply ring-1 ring-red-500 border-red-500;
  }

  &.input-lg {
    @apply text-lg;
  }
}

.input-addon {
  @apply flex items-center px-4 rounded-md border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700;
}

.input-textarea {
  min-height: 7rem;
}

.input-wrapper {
  @apply relative w-full flex;
}

.input-disabled {
  @apply bg-gray-100  text-gray-400 cursor-not-allowed;

  &::placeholder {
    @apply opacity-70;
  }
}
.input-group .date-picker.picker-expanded,
.input-group .input-suffix-end,
.input-group .input-suffix-start,
.input-group .select__control--is-focused {
  z-index: 20;
}
.input-suffix-start {
  @apply absolute top-2/4 transform -translate-y-2/4 left-2.5;
}

.input-suffix-end {
  @apply absolute top-2/4 transform -translate-y-2/4 right-2.5 flex;
}

.notification {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  margin-bottom: 0.75rem;
  position: relative;
}
.dark .notification,
.notification {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .notification {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: transparent;
}
.notification-content {
  display: flex;
  padding: 1rem;
}
.notification-content.no-child {
  align-items: center;
}
.notification-title {
  font-family: "outfit";
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  font-weight: 500;
}
.dark .notification-title {
  font-family: "outfit, sans-serif";
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.notification-close {
  top: 1.25rem;
}
[dir="ltr"] .notification-close {
  right: 1rem;
}
[dir="rtl"] .notification-close {
  left: 1rem;
}


/* calendar css*/

.picker-table,
.picker-view {
  width: 100%;
}
.picker-view {
  display: flex;
  gap: 1.5rem;
  min-width: 260px;
}
.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}
.picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}

/* button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
} */
.picker-table {
  border-collapse: collapse;
}
.picker-table,
.picker-view {
  width: 100%;
}
.week-day-cell {
  height: 1.75rem;
}
td,
th {
  word-wrap: break-word;
}
.date-picker-week-cell {
  font-weight: 600;
}
.date-picker-cell {
  height: 2.25rem;
  padding: 0;
  text-align: center;
  width: 2.25rem;
}
.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.date-picker-cell-content {
  font-weight: 400;
  height: 100%;
  width: 100%;
}
.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}
.month-table,
.year-table {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.date-picker-cell-disabled {
  --tw-bg-opacity: 1;
  cursor: not-allowed;
  opacity: 0.3;
}


*,
::backdrop,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}

.animate {
  width: 100px;
  height: 100px;
}

/* logo slider */
.img img {
  width: 100%;
}
.input {
  padding-inline-start: 36px;
}
._2Jtxm._35Ago ._2kL0S {
  background-color: white !important;
  border: 2px solid #003a7b;
  color: #003a7b !important;
}
._2Jtxm._2ZUAI ._2JvrO {
  background-color: #003a7b !important;
}
._2Jtxm::before {
  background: linear-gradient(to left, #003a7b 50%, #003a7b 50%) !important;
}
._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
  background-color: white !important;
  border: 2px solid #003a7b;
}
/* ._2pGos._2RWmX{
  display: none;
} 
._2pGos._hsN1w {
  display: none;
} */

@media (max-width: 450px) {
  ._2Jtxm ._1hzhf {
    font-size: 10px;
  }
}


.tab-list {
  align-items: center;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.tab-list-underline {
  border-bottom-width: 2px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.dark .tab-list-underline,
.tab-list-underline {
  --tw-border-opacity: 1;
}
.dark .tab-list-underline {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.tab-nav {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tab-nav.tab-nav-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.tab-nav-underline {
  border-bottom-width: 2px;
  border-color: #0000;
  /* padding: 0.75rem 1.25rem; */
}
.tab-nav-pill {
  border-radius: 0.375rem;
  margin-right: 0.25rem;
  padding: 0.5rem 1.25rem;
}
[dir="rtl"] .tab-nav-pill {
  margin-left: 0.25rem;
}
.tab-nav-icon {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
[dir="ltr"] .tab-nav-icon {
  margin-right: 0.375rem;
}
[dir="rtl"] .tab-nav-icon {
  margin-left: 0.375rem;
}
.tab-nav-active {
  border-bottom: 2px solid #504cbc;
}

.toast {
  position: fixed;
  z-index: 40;
}

.tooltip {
  --tw-text-opacity: 1;
  border-radius: 0.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 250px;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 50;
}
.tooltip-wrapper {
  display: inline-flex;
}

.upload {
  display: inline-block;
  position: relative;
}
.upload.disabled {
  opacity: 0.6;
}
.upload.disabled,
.upload.disabled .upload-input {
  cursor: not-allowed;
}
.upload-input {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.upload-input.draggable {
  cursor: pointer;
  display: block;
  opacity: 0;
  width: 100%;
}
.upload-draggable {
  --tw-border-opacity: 1;
  align-items: center;
  /* border-color: rgba(209, 213, 219, var(--tw-border-opacity)); */
  border-color: #003A7B;
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 7rem;
}
.dark .upload-draggable {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.upload-file-list {
  margin-top: 1rem;
}
.upload-file-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
}
[dir="ltr"] .upload-file-info {
  margin-left: 0.75rem;
}
[dir="rtl"] .upload-file-info {
  margin-right: 0.75rem;
}
.upload-file {
  align-items: center;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.dark .upload-file,
.upload-file {
  --tw-border-opacity: 1;
}
.dark .upload-file {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.upload-file-thumbnail {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  padding: 0.5rem;
  width: 4rem;
}
.upload-file-image {
  max-width: 100%;
}
.upload-file-remove {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.75rem;
}

