.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a.active {
  text-decoration: underline;
  color: black;
}

.nsm7Bb-HzV7m-LgbsSe:hover,
.nsm7Bb-HzV7m-LgbsSe:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  /* border-color: #dadce0  !important; */
  outline: none;
}

/* This targets the SVG icon specifically within the Google button */
.nsm7Bb-HzV7m-LgbsSe-Bz112c svg {
  min-width: 20px; /* Set the size you want */
  min-height: 20px;
  position: relative;
  bottom: 8px;
}

.react-apple-signin-auth-btn {
  padding: 0px !important;
  width: 40px;
  height: 40px;
}

.react-apple-signin-auth-btn-light {
  background-color: #fff;
  color: #3c4043 !important;
  border-color: #dadce0 !important;
}

.react-apple-signin-auth-btn-light:hover {
  background-color: #fff;
  color: #3c4043 !important;
  border-color: #d2e3fc !important;
}
